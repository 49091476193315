body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  color: #000000;
  overflow-x: hidden;
}
a {
  color: #feba31;
  text-decoration: none;
}
a:hover {
  color: #ffa719;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #d5781d;
}
.brand_logo {
  width: 190px;
}
.main_navbar {
  background-color: transparent !important;
  z-index: 10;
  transition: 0.2s all;
}
.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #fff !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 999;
  /* padding: 15px 0; */
}
.navbar_right li a {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none !important;
}
.navbar_right li a:hover,
.navbar_right li a.active {
  color: #d5781d;
}

.logout_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}
.logout_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}
.logout_btn img {
  margin-right: 10px;
}
.page_header {
  position: relative;
  padding: 150px 0 120px;  
  width: 100%;
  height: 100%;
  /* margin-top: -75px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_header h1 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 56px;
}
.page_header h5 {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}
.orange_txt {
  color: #ffa719 !important;
}
.banner_img {
  text-align: center;
}
.banner_img img {
  border-radius: 50px;
}
section {
  padding: 80px 0;
}
.main_title_small {
  font-size: 20px;
  font-weight: 400;
  padding-left: 26px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  margin-bottom: 20px;
  display: inline-block;
}
.main_title_small::before {
  content: "";
  background: #ffa700;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  position: absolute;
  top: -12px;
  left: 0;
  z-index: -1;
}
.main_title_small::after {
  content: "";
  background: #ffa700;
  width: 92%;
  height: 2px;
  position: absolute;
  bottom: -4px;
  z-index: -1;
  left: 15px;
}
.main_title {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 30px;
}
.inner_para {
  font-size: 16px;
}
.primary_box {
  display: flex;
  justify-content: center;
  flex-direction: column; 
  align-items: flex-start;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  background: #fbfbfb;
  background-clip: padding-box;
  border: 2px solid #fbfbfb;
  border-radius: 20px;
  transition: 0.2s all;
}
.primary_box.features_box img {
  margin-bottom: 20px;
}
.primary_box.features_box:hover {
  box-shadow: inset 4px 2px 3px 1px rgb(0 0 0 / 10%);
}
.grey_bg {
  background: #f7f7f7;
}
.whyus_section {  
  padding-bottom: 150px !important;
}
.features_box {  
  cursor: default;
  height: 100%;
  min-height: 360px;
}
.primary_box.features_box p {
  max-width: 92%;
  color: #595959;
  font-size: 16px;
}
.primary_box.features_box:hover::before {
  transform: scale(1.1);
}
.features_box h4 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 20px;
  max-width: 70%;
}
.features_box > div {
  margin-bottom: 20px;
}
.primary_box p {
  margin-bottom: 0;
}
.whyus_section [class*=col-] {
  margin-bottom: 30px;
}
.cta_section_01 {
  background: #555bef url("../src/assets/images/cta_01_bg.png") right center no-repeat;
  border: 2px solid #555bef;
  padding: 40px 50px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  margin-top: -185px;
}
.cta_section_01 h4 {
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}
.cta_section_01 h2 {
  font-size: 70px;
  font-weight: 700;
  margin: 0;
  color: #ffffff;
  text-transform: uppercase;
}
.cta_section_01 img {
  position: absolute;
  top: -48px;
  right: 110px;
}
#faq_accodian {
  margin-top: 50px;
}
#faq_accodian .accordion-header button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-decoration: none;
  background: transparent;
}
#faq_accodian .accordion-header,
#faq_accodian .accordion-body {
  background-color: transparent;
}
#faq_accodian .accordion-item {
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  color: #333333;
  background: #fbfbfb;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
  transition: 0.2s all;
  margin-bottom: 10px;
  overflow: unset;
  box-shadow: inset 0px 0 5px 0px rgb(0 0 0 / 10%);
}
#faq_accodian .accordion-body {
  padding-top: 0;
}
#faq_accodian .accordion-body p {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  cursor: default;
}
#faq_accodian .accordion-button::after {
  /* filter: invert(1) contrast(0) brightness(1); */
  background-image: url("./assets//images/plus_icon.png");
}
#faq_accodian .accordion-button:not(.collapsed)::after {
  background-image: url("./assets//images/minus_icon.png");
}
.footer {
  background-color: #1f24a2;
  padding: 30px 0 20px;
}
.footer .main_title_small,
.footer .main_title {
  color: #fff;
}
.footer_panel_bottom p {
  margin-bottom: 0;
  font-size: 16px;
  color: #fff;
}
.footer_panel_bottom a {
  font-size: 18px;
  color: #feba31;
  white-space: normal;
  word-break: break-all;
}
.form_inline {
  display: flex;
}
.form_inline .primary_btn {
  border-radius: 10px;
}
.footer_panel_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #8487cc;
}
.footer_panel_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 0;
}
.form_inline input.form-control {
  margin-right: 15px;
  width: 350px;
}
.footer_social_links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer_social_links li {
  margin: 0 0 0 30px;
}
.footer_social_links li a {  
  color: #8487cc;
  font-size: 32px;
  text-decoration: none;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  color: #fff;
}
.primary_btn {  
  padding: 8px 35px;
  font-size: 24px;
  border: 1px solid #d5781d;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;  
  text-align: center;
  user-select: none;
  transition: 250ms all ease-in-out;
  color: #fff !important;
  text-decoration: none !important;
  background: #d5781d;
}
.primary_btn:hover {
  color: #d5781d !important;
  background: transparent;
}
.primary_btn a {
  color: #fff !important;
  text-decoration: none;
}
.primary_btn:hover a {
  color: #d5781d !important;
}
.primary_register_btn {
  color: #1f20a7 !important;
  border: 1px solid #bdcbfa;
  border-radius: 30px;
  padding: 6px 20px;
  transition: 250ms all ease-in-out;
}
.primary_register_btn:hover {
  text-decoration: none;
  color: #fff !important;
  background: #1f20a7;
}
.navbar_right .primary_btn {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 20px;
  margin-right: 15px;
}
.d_d_none {
  display: none !important;
}
.inner_para a {
  word-break: break-all;
}
.roadmap_slider::before {
  border-top: 1px solid #ebddcf;
  content: "";
  width: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 40px;
}
.roadmap_slider .slick-list {
  padding-top: 50px;
}
.roadmap_slider .slick-slide::before {
  content: "";
  background: url("./assets/images/slider_dot.png") no-repeat;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -18px;
  filter: grayscale(1);
  opacity: 0.5;
  transition: 0.2s all;
  margin-left: 46px;
}
.roadmap_slider_div {
  max-width: 70%;
  border: 1px solid #cecece;
  background: transparent;
  padding: 15px;
  position: relative;
  border-radius: 20px;
  margin-top: 30px;
}
.roadmap_slider_div::before {
  content: "";
  background: url("./assets/images/sliderbox_top.png") no-repeat;
  width: 47px;
  height: 27px;
  position: absolute;
  top: -22px;
  left: 30px;
}
.roadmap_slider .slick-slide.slick-active.slick-current .roadmap_slider_div::before {
  background: url("./assets/images/sliderbox_top_active.png");
}
.roadmap_slider_div h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.roadmap_slider_div p {
  font-size: 16px;
  margin-bottom: 0;
}
.roadmap_slider .slick-slide.slick-active.slick-current::before {
  filter: unset;
  opacity: 1;
}
.roadmap_slider .slick-slide.slick-active.slick-current .roadmap_slider_div {
  background: #d5781d;
  border: 1px solid #d5781d;
}
.roadmap_slider .slick-slide.slick-active.slick-current .roadmap_slider_div h3 ,
.roadmap_slider .slick-slide.slick-active.slick-current .roadmap_slider_div p {
  color: #fff;
}
.roadmap_slider button.slick-arrow.slick-prev::before,
.roadmap_slider button.slick-arrow.slick-next::before {
  display: none;
}
.roadmap_slider button.slick-arrow.slick-prev {
  background: url("./assets/images/prev_icon.png") no-repeat;
}
.roadmap_slider button.slick-arrow.slick-next {
  background: url("./assets/images/next_icon.png") no-repeat;
}
.roadmap_slider button.slick-arrow.slick-prev,
.roadmap_slider button.slick-arrow.slick-next {
  width: 32px;
  height: 32px;
  top: 0;
  top: 40px;
  filter: grayscale(1) opacity(0.8);
}
.roadmap_slider button.slick-arrow.slick-prev:hover,
.roadmap_slider button.slick-arrow.slick-next:hover {
  filter: unset;
}
.chart_list {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  max-width: 56%;
}
.chart_list li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chart_list li span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
.chart_list li::before {
  content: "";
  background-color: #30941d;
  width: 11px;
  height: 24px;
  position: absolute;
  left: 20px;
  border-radius: 30px;
}
.chart_list li:nth-child(2):before {
  background-color: #be270a;
}
.chart_list li:nth-child(3):before {
  background-color: #9ea000;
}
.chart_list li:nth-child(4):before {
  background-color: #0487a1;
}
.chart_list li:nth-child(5):before {
  background-color: #1a9b28;
}
.chart_list li:nth-child(6):before {
  background-color: #b74e0d;
}
.chart_list li:nth-child(7):before {
  background-color: #bfb223;
}
.chart_list li:nth-child(8):before {
  background-color: #35c48f;
}
.chart_list li:nth-child(9):before {
  background-color: #195bb5;
}
.our-team{
  text-align: center;
  transition: all 0.5s ease 0s;
}
.our-team .pic{
  overflow: hidden;
  position: relative;
}
.our-team .pic:before,
.our-team .pic:after {
  content: "";
  width: 200%;
  height: 80%;
  background: rgb(255 167 0 / 70%);
  position: absolute;
  top: -100%;
  left: -3%;
  transform: rotate(45deg);
  transition: all 0.5s ease 0s;
}
.our-team .pic:after {
  background: rgb(255 167 0 / 70%);
  top: auto;
  left: auto;
  bottom: -100%;
  right: -4%;
}
.our-team:hover .pic:before { 
  top: 0; 
}
.our-team:hover .pic:after { 
  bottom: 0; 
}
.our-team .pic img {
  width: 100%;
  height: auto;
}
.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  bottom: 45%;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.5s ease 0.3s;
}
.our-team:hover .social { 
  opacity: 1; 
}
.our-team .social li { 
  display: inline-block; 
}
.our-team .social li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}
.our-team .social li a:after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 20%);
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.our-team .social li a:hover:after {
  background: #2a4393;
}
.our-team .team-content { 
  padding: 20px; 
}
.our-team .title {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  text-transform: capitalize;
  margin-bottom: 7px;
}
.our-team .post {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  text-transform: capitalize;
}
@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px; 
  }
}



/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;  
}
.inner_wrapper::before {
  content: "";
  /* background: url("../src/assets/images/banner_bg.png") no-repeat; */
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top center;
  position: absolute;
  top: -80px;
  z-index: -1;
}
.dashboard_panel {
  background: #0b162e;
  border-radius: 20px;
  padding: 30px;
}
.dashboard_panel.join_now_panel {
  min-height: 92vh;
}
.title_flex {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
.inner_title {
  font-size: 48px;
  font-weight: 700;
  color: #feba31;
  margin-bottom: 0;
}
.wallet_div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet_div label {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  margin: 0px 5px 0 0;
}
.primary_inp {
  border: 1px solid #223359;
  background: #0b162e;
  color: #fff !important;
  font-size: 16px;
  border-radius: 10px;
  padding: 12px;
}
.primary_inp:focus {
  border: 1px solid #223359;
  background: #0b162e;
  box-shadow: none !important;
}
.primary_inp::placeholder {
  color: #909090;
}
.inp_grp {
  position: relative;
}
.inp_append {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}
.wallet_div input.primary_inp {
  width: 350px;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #617398 !important;
  font-size: 20px;
  padding: 2px;
}
button.round_btn {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
img.copy_icon.wallet_icon {
  margin-right: 0;
}
.wallet_div .inp_grp {
  display: flex;
  align-items: center;
}
.last_login_details {
  font-size: 16px;
  font-weight: 200;
}
.dash_box_left {
  padding: 10px 0px;
  border-bottom: 1px solid #031542;
}
.dash_box_left:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.dash_box_left:first-child {
  padding-bottom: 20px;
}
.dash_box_left h2 {
  font-size: 24px;
  font-weight: 700;
}
.dash_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 span {
  color: #e8d7f8;
  font-size: 20px;
}
.dash_box_left p {
  color: #e8d7f8;
  font-size: 18px;
  margin-bottom: 0;
}
.dash_box_left h4 {
  color: #e8d7f8;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dashbox_middle_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}
.dashbox_middle_top h2 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.dashbox_middle_top h4 {
  font-size: 18px;
  font-weight: 400;
  color: #617398;
}
.dashbox_middle_top > div {
  display: flex;
  align-items: center;
  cursor: default;
}
.dashbox_middle_bottom h2 {
  font-size: 20px;
  font-weight: 700;
  color: #feba31;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.dashbox_middle_bottom h5 {
  font-size: 24px;
  font-weight: 500;
}
.dashboard_top_wrap [class*=col-] {
  margin-bottom: 30px;
}
.dashbox_middle_bottom ul.footer_social_links li a {
  width: 28px;
  height: 28px;
  font-size: 14px;
}
.dashbox_middle_bottom ul.footer_social_links {
  justify-content: center;
  margin-top: 15px;
}
.dashbox_middle_bottom ul.footer_social_links li {
  margin: 0 10px 0px 0;
}
.dashboard_top_wrap > [class*=col-]:last-child {
  margin-bottom: 0;
}
.search_div {
  width: 50%;
  margin: 50px auto 0;
}
.search_div button {
  background: #feba31;
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: #0b162e;
  position: absolute;
  top: 1px;
  right: 0;
  border-radius: 0 10px 10px 0;
  padding: 6px 20px;
}
.plan_wrapper {
  /* display: flex;
  flex-flow: wrap;
  justify-content: center; */
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4,minmax(0,1fr));
}
.buy_item {
  background-color: #0b162e;
  padding: 20px 10px 20px;
  border-radius: 20px;
  position: relative;
  /* width: 200px; */
  margin: 50px 0 20px;
  cursor: default;
}
.buy_item h4 {
  font-size: 16px;
  font-weight: 500;
  color: #feba31;
}
.buy_item h5 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.buy_item .primary_btn {
  padding: 4px 12px;
  font-size: 16px;
  margin: 15px auto 0;
  width: auto !important;
}
.dashbox_middle_bottom .primary_btn {
  width: 100%;
  justify-content: center;
}
.buy_count {
  background: #feba31;
  border: 1px solid #ffd47e;
  box-shadow: 0 0 5px 2px rgb(254 186 49 / 70%);
  position: relative;
  border-radius: 50%;
  margin: -75px auto 15px;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buy_count p {
  font-size: 14px;
  color: #0b162e;
  font-weight: 700;
}
.buy_count h2 {
  font-size: 36px;
  color: #0b162e;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 40px;
}
.buy_count h3 {
  font-size: 24px;
  color: #0b162e;
  font-weight: 700;
  margin: 0;
}
.buy_data {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.buy_data_item {
  display: flex;
  align-items: center;
}
.buy_data_count p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  margin-left: 5px;
}
.chance_panel h2 {
  font-size: 24px;
  text-transform: uppercase;
}
.buy_item.buy_item_disabled {
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}
.buy_item:hover .buy_count {
  animation-name: hvr-pulse;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.primary_box.dash_box {
  padding: 20px;
}
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #111f3d;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #223359;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid #223359;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}
.connect_wallet_button:hover {
  border-color: #feba31;
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
}
.primary_modal a {
  text-decoration: none;
}
.node circle {
	fill: #feba31 !important;
	stroke: #feba31 !important;
	stroke-width: 1.5px  !important;
  /* background: url("../src/assets/images/tree_icon.png"); */
  width: 13px;
  height: 21px;
}
.node text {
	font-size: 11px  !important;
	background-color: #444  !important;
	fill: #fff  !important;
}
.node {
	cursor: pointer;
}
path.link {
	stroke: #feba31 !important;
	stroke-width: 1.5px !important;
}
.dash_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  cursor: pointer;
  margin-right: 15px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #feba31;
  box-shadow: 0 0 0 3px #feba31;
  transition: color 0.3s;
}
.dash_icon:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #feba31;
  transition: transform 0.2s, opacity 0.2s;
}
.dash_icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dashbox_middle_top > div:hover .dash_icon::after {
  transform: scale(0.85);
}
.address_btn {
  display: inline-block;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 200px;
}
ul.walletdrop {
  background: #111f3d;
  border: 1px solid #223359;
}
ul.walletdrop li a {
  color: #eee;
  text-decoration: none;
}
ul.walletdrop li a:hover {
  background-color: #feba31;
}


/* Media Queries */
@media screen and (max-width: 1199px) {
  .dashbox_middle_top h2 {
    font-size: 32px;
  }
  .cta_section_01 {
    background-size: 100%;
  }
  .plan_wrapper {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
  .dash_icon {
    width: 90px;
    height: 90px;
  }
  .main_navbar {
    background-color: #fff !important;
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 10%);
  }
  .navbar_right ul {
    display: block;
    padding: 20px 0 30px;
  }
  .navbar_right .primary_btn {
    margin-right: 0;
    width: 150px;
    margin-bottom: 15px;
    text-align: center;
  }
  .primary_register_btn {
    width: 150px;
    text-align: center;
  }
  button.navbar-toggler {
    box-shadow: none !important;
  }
  .navbar_right li a {
    padding-left: 0;
  }
  .cta_section_01 h2 {
    font-size: 50px;
  }
  .cta_section_01 img {   
    top: -71px;
  }
}
@media screen and (max-width: 991px) {
  .wallet_div {
    display: block;
  }
  .inner_title {
    margin-bottom: 15px;
  }
  .wallet_div .inp_grp {
    justify-content: center;
  }
  .wallet_div label {
    margin: 0 !important;
  }
  .footer_panel {
    display: block;
    text-align: center;
  }
  .footer_social_links {
    justify-content: center;
    margin-top: 15px;
  }
  .page_header h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .page_header h5 {
    max-width: 80%;
    text-align: center;
    margin: 0 auto 20px;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .page_header { 
    height: auto;
    text-align: center;
  }
  .dashbox_middle_top {
    flex-wrap: wrap;
  }
  .dashbox_middle_top > div {
    margin-bottom: 15px;
  }
  .buy_count h2 {
    font-size: 28px !important;    
    line-height: 32px;
  }
  .buy_count h3 {
    font-size: 20px;
  }
  .footer_panel_top {
    display: block;
    padding-bottom: 30px;
  }
  .form_inline {
    justify-content: center;
  }
  .footer_panel_bottom {
    display: block;
  }
  .footer_social_links li {
    margin: 0 15px;
  }
  .token_section .col-md-12.col-lg-6 {
    text-align: center;
    margin-bottom: 50px;
  }
  .cta_section_01 img {
    top: -39px;
    right: 0;
    width: 30%;
  }
  .cta_section_01 h2 {
    font-size: 42px;
  }
  .cta_section_01 h4 {
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .dashbox_middle_top {
    display: block;
  }
  .dashbox_middle_top > div {
    margin-bottom: 30px;
  }
  .dashbox_middle_top > div:last-child {
    margin-bottom: 0;
  }
  .dashbox_middle_top h2 {
    margin-bottom: 0;
  }
  .search_div {
    width: 100%;
  }
  .footer_panel_bottom a {
    font-size: 20px;
  }
  button.logout_btn {
    margin-bottom: 15px;
  }
  .navbar_right li a {
    padding-left: 10px;
  }
  .page_header h1 {
    font-size: 36px;
  }
  .page_header h5 {
    max-width: 100%;
    font-size: 20px;
  }
  .main_title {
    font-size: 36px;
  }
  .last_login_details {
    margin: 15px 0 0;
  }
  .inner_title {
    font-size: 36px;
  }
  .chart_list {
    max-width: 100%;
  }
  .cta_section_01 img {
    display: none;
  }
  .cta_section_01 {
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .dashbox_middle_bottom h2 {
    font-size: 18px;
  }
  .inner_para {
    font-size: 18px;
  }
  .main_title_small {
    font-size: 20px;
  }
  .main_title {
    font-size: 28px;
  }
  .main_navbar .container-fluid {
    display: block;
    text-align: center;
  }  
  .primary_btn {
    padding: 12px 25px;
    font-size: 16px;
  }  
  .plan_wrapper {
    grid-template-columns: repeat(1,minmax(0,1fr));
  }
  .wallet_div input.primary_inp {
    width: auto;
    text-overflow: ellipsis;
  }
  .dash_icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 15px;
  }
  .dashbox_middle_top > div {
    display: block;
    text-align: center;
  }
  .dashbox_middle_top h2 {
    text-align: center;
    font-size: 28px;
  }
  .dash_icon img {
    width: 32px;
  }
}